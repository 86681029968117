<template>
    <v-card>
        <MyCardTitle modulo="Paciente" :cardInfo="paciente" />
        <MyEnderecos modulo="pacientes" :object-current="paciente"/>    
    </v-card>
</template>
<script>
import api from '@/http'
export default{
    name: "Enderecos",
    components:{
        MyCardTitle: ()=>import('../../../uteis/card_title.vue'),
        MyEnderecos: ()=>import('../endereco.vue'),
    },
    props:{
        paciente: {
            type:Object
        }
    },
    data:() => ({   
        search:'',
        loading_equipe_multidisciplinar:false,
        equipe_multidisciplinar:[],
        check_emd:[],
        header_em:[
            {text:"Id", value:"id"},
            {text:"Username", value:"username"},
            {text:"Nome", value:"nome"},
            {text:"Especialidade", value:"especialidade.nome"},
            {text:"Edit", value:"edit"},
            {text:"Modalidade", value:"modalidade.nome"},
        ]
    }),
    methods:{
        get_equipe_multidisciplinar(){  
            this.loading_plano = true
            api(`contas/equipe-multidisciplinar/`)
            .then((e) => {
              e
            })      
            .finally(()=>{ this.loading_plano = false; });
        }
    },
    mounted(){
       // this.get_equipe_multidisciplinar()
    }
}
</script>